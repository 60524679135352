import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={48} height={48} rx={24} fill="#CFF9EF" />
      <path
        d="M26.876 27.93V30h-2.355v-2.07h-4.995v-2.115l4.32-6.435h3.015v6.51h1.47v2.04h-1.455zm-2.34-5.97h-.045l-2.52 3.93h2.565v-3.93z"
        fill="#25DAB0"
      />
    </svg>
  )
}

export default SvgComponent
