import React from "react"
import TextLoop from "react-text-loop"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// SVG
import Number1 from "../components/svg/about/about-1-svg"
import Number2 from "../components/svg/about/about-2-svg"
import Number3 from "../components/svg/about/about-3-svg"
import Number4 from "../components/svg/about/about-4-svg"
import Number5 from "../components/svg/about/about-5-svg"
import Number6 from "../components/svg/about/about-6-svg"

// Images
import ImageBanner from "../components/images/about/about-banner-image"

const AboutPage = () => {
  const gridItems = [
    {
      svg: <Number1 />,
      heading: "OK is not OK",
      body:
        "At UnicornBox, we always try to deliver the best. We just won't try to exceed your expectations but also the people around us.",
    },
    {
      svg: <Number2 />,
      heading: "Curiosity fuels the mind",
      body:
        "Curiosity may have killed the cat, but the cat surely lived a great life. Nothing great comes when you stay in your comfort zone. Go out there and explore.",
    },
    {
      svg: <Number3 />,
      heading: "Toxic environment creates toxic outputs",
      body:
        "Rome wasn't built in a day. Great things require conscious and proactive effort for it to materialize and this behavior should start within our company. We make sure that not only is the client happy, but also the people in the company.",
    },
    {
      svg: <Number4 />,
      heading: "Don't wait for the apple to fall",
      body:
        "If you have a goal, just waiting would get you nowhere. Act now and if you get stuck, there will be people around you who will pick you up and push you forward.",
    },
    {
      svg: <Number5 />,
      heading: "Seniority does not equate infallibility",
      body:
        "Trust your gut if you believe you're in the right. Do not be afraid to defend your idea to those above you.",
    },
    {
      svg: <Number6 />,
      heading: "Real recognizes real",
      body:
        "We're all in this together. A simple 'hey, you did a great job' would make a difference in a person's morale. High team morale = great output and awesome environment.",
    },
  ]

  const buildSoftwareFor = [
    "innovators",
    "world changers",
    "disruptors",
    "dreamers",
    "idealists",
    "trailblazers",
    "thinkers",
  ]

  return (
    <Layout>
      <SEO
        title="About"
        description="UnicornBox. The studio where art meets technology."
      />

      <div className="max-w-270 mt-6 md:mt-23 mx-10 xl:mx-auto mb-14 md:mb-35">
        <h1 className="text-h3 md:text-h1 font-heading font-bold text-black max-w-210">
          UnicornBox. The studio where <span className="text-brand">art</span>{" "}
          meets <span className="text-brand">technology</span>.
        </h1>
      </div>

      <div className="py-14 px-10 md:py-27 xl:px-0 w-full bg-gray-1">
        <div className="md:flex max-w-270 mx-auto">
          <div className="md:w-1/3 md:mr-22">
            <h3 className="text-h4 md:text-h3 font-heading font-bold text-black">
              What We Do
            </h3>
            <p className="mt-2 md:mt-5 text-small md:text-regular font-paragraph font-normal text-black">
              With years of collective technical experience and expertise
              backing us up, we assist individuals and organizations navigate
              their respective markets and help deploy successful and disruptive
              applications.
            </p>
          </div>
          <div className="md:w-2/3 mt-10 md:mt-0 text-regular font-paragraph font-normal text-black">
            <p className="text-smallHeading font-paragraph font-medium text-black uppercase tracking-widest">
              Helping companies and individuals move forward through
            </p>
            <h3 className="mt-2 md:mt-5 text-h4 md:text-h3 font-heading font-normal text-brand">
              Web Development, Mobile Development, and Consulting
            </h3>
          </div>
        </div>
      </div>

      <ImageBanner className="w-full">
        <div className="bg-banner-overlay w-full h-full">
          <h2 className="text-h3 md:text-h2 font-heading font-bold text-center text-white py-14 md:py-45 px-10 md:px-0">
            We build software for the{" "}
            <TextLoop interval={1500}>
              {buildSoftwareFor.map((softFor, index) => (
                <u key={index}>{softFor}</u>
              ))}
            </TextLoop>
            .
          </h2>
        </div>
      </ImageBanner>

      <div className="max-w-270 my-14 md:my-28 mx-10 xl:mx-auto">
        <div className="w-full xl:w-1/2">
          <h3 className="text-h4 md:text-h3 font-heading font-bold text-black">
            Our purpose and values drive our company
          </h3>
          <p className="mt-2 md:mt-5 text-small md:text-regular font-paragraph font-normal text-black">
            As UnicornBox continues to grow and evolve through experience, we
            remain grounded and inspired by our core purpose and value. We want
            to epitomise our belief by producing products that are not only
            competitive but of quality and relevant to society.
          </p>
        </div>

        <div className="grid md:grid-cols-3 grid-rows-6 md:grid-rows-2 gap-10 md:gap-5 mt-10 md:mt-20">
          {gridItems.map((item, index) => (
            <div key={index}>
              {item.svg}
              <p className="text-small font-paragraph font-bold text-black mt-4">
                {item.heading}
              </p>
              <p className="text-small font-paragraph font-normal text-black mt-2">
                {item.body}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
