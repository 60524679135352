import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={48} height={48} rx={24} fill="#CFF9EF" />
      <path
        d="M27.881 26.49c0 .65-.115 1.215-.345 1.695-.23.47-.54.86-.93 1.17-.38.31-.815.54-1.305.69-.49.16-.995.24-1.515.24-.48 0-.94-.065-1.38-.195a4.367 4.367 0 01-1.185-.525c-.35-.23-.655-.51-.915-.84-.25-.34-.435-.72-.555-1.14l2.295-.705c.11.37.315.675.615.915.31.24.675.36 1.095.36.43 0 .8-.13 1.11-.39.32-.26.48-.645.48-1.155 0-.31-.065-.57-.195-.78-.13-.21-.3-.375-.51-.495-.2-.13-.43-.22-.69-.27-.26-.05-.52-.075-.78-.075-.38 0-.815.045-1.305.135-.48.08-.91.19-1.29.33l.255-6.075h6.51v2.16h-4.275l-.09 1.68c.16-.04.335-.065.525-.075a5.229 5.229 0 012.04.18c.47.14.875.355 1.215.645.35.28.625.63.825 1.05.2.42.3.91.3 1.47z"
        fill="#25DAB0"
      />
    </svg>
  )
}

export default SvgComponent
