import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={48} height={48} rx={24} fill="#CFF9EF" />
      <path
        d="M23.396 30v-7.8l-1.98 1.53-1.305-1.785 3.495-2.565h2.31V30h-2.52z"
        fill="#25DAB0"
      />
    </svg>
  )
}

export default SvgComponent
