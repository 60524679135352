import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={48} height={48} rx={24} fill="#CFF9EF" />
      <path
        d="M20.171 30v-2.1l4.14-3.75c.26-.25.47-.505.63-.765.16-.26.24-.55.24-.87 0-.38-.12-.685-.36-.915-.24-.24-.56-.36-.96-.36-.42 0-.765.15-1.035.45-.26.29-.415.675-.465 1.155l-2.415-.33c.06-.54.205-1.02.435-1.44.24-.42.54-.775.9-1.065.36-.3.77-.525 1.23-.675.47-.16.965-.24 1.485-.24.49 0 .965.065 1.425.195.46.13.87.33 1.23.6.36.27.645.61.855 1.02.22.41.33.89.33 1.44 0 .38-.05.725-.15 1.035-.1.3-.24.58-.42.84-.17.26-.37.505-.6.735-.22.22-.45.44-.69.66l-2.46 2.19h4.335V30h-7.68z"
        fill="#25DAB0"
      />
    </svg>
  )
}

export default SvgComponent
