import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={48} height={48} rx={24} fill="#CFF9EF" />
      <path
        d="M28.166 26.43c0 .6-.11 1.14-.33 1.62-.22.48-.52.885-.9 1.215-.38.33-.825.58-1.335.75-.51.18-1.05.27-1.62.27-.58 0-1.125-.09-1.635-.27-.5-.17-.94-.42-1.32-.75-.38-.33-.68-.73-.9-1.2a3.67 3.67 0 01-.33-1.575c0-.63.11-1.2.33-1.71a9.79 9.79 0 01.855-1.575l2.565-3.825h3.045l-2.61 3.63-.045.075c.1-.04.22-.07.36-.09.14-.02.27-.03.39-.03.46 0 .9.085 1.32.255.43.16.8.395 1.11.705.32.3.575.665.765 1.095.19.42.285.89.285 1.41zm-2.52.06c0-.48-.15-.875-.45-1.185-.3-.32-.7-.48-1.2-.48s-.905.155-1.215.465c-.31.31-.465.715-.465 1.215 0 .46.15.855.45 1.185.3.33.705.495 1.215.495.5 0 .9-.155 1.2-.465.31-.31.465-.72.465-1.23z"
        fill="#25DAB0"
      />
    </svg>
  )
}

export default SvgComponent
