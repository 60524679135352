import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={48} height={48} rx={24} fill="#CFF9EF" />
      <path
        d="M27.896 26.91c0 .58-.12 1.085-.36 1.515-.23.42-.535.77-.915 1.05-.38.27-.81.47-1.29.6-.47.14-.95.21-1.44.21-.48 0-.95-.055-1.41-.165a4.695 4.695 0 01-1.245-.495c-.37-.23-.695-.52-.975-.87a3.7 3.7 0 01-.63-1.29l2.34-.615c.1.35.305.66.615.93.31.26.705.39 1.185.39.18 0 .36-.025.54-.075a1.5 1.5 0 00.495-.225c.15-.11.27-.25.36-.42.1-.17.15-.38.15-.63 0-.27-.065-.495-.195-.675a1.2 1.2 0 00-.495-.42c-.2-.11-.43-.185-.69-.225-.26-.05-.52-.075-.78-.075h-.675V23.58h.735c.24 0 .47-.015.69-.045.22-.04.415-.105.585-.195.18-.1.32-.23.42-.39.11-.16.165-.37.165-.63 0-.38-.135-.67-.405-.87-.27-.2-.58-.3-.93-.3-.36 0-.675.11-.945.33-.26.21-.43.495-.51.855l-2.34-.54c.13-.45.325-.84.585-1.17.26-.34.565-.62.915-.84.36-.23.75-.4 1.17-.51.42-.12.85-.18 1.29-.18.47 0 .925.06 1.365.18.45.12.85.305 1.2.555.35.25.63.565.84.945.22.37.33.81.33 1.32 0 .59-.17 1.09-.51 1.5-.33.4-.755.67-1.275.81v.045c.3.07.575.185.825.345.25.15.465.335.645.555.18.21.32.45.42.72.1.26.15.54.15.84z"
        fill="#25DAB0"
      />
    </svg>
  )
}

export default SvgComponent
